<template>
  <aside class="menu">
    <p class="menu-label mb-0">
      <router-link to="/member">
        <img id="logo" src="/tnj-logo.png">
      </router-link>
    </p>
    <hr class="my-0">
    <ul class="menu-list">
      <li v-for="(menu,key) in menus" :key="key">
        <a class="pl-1" :class="{'is-active':currentPage(menu)}" @click="redirect(menu.href)" v-if="menu.href">
          <i :class="menu.icon"></i>
          <span class="pl-1">{{menu.title}}</span>
        </a>
        <a class="pl-1" @click="$emit(menu.emit)" v-else>
          <i :class="menu.icon"></i>
          <span class="pl-2">{{menu.title}}</span>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    menus: {
      type: Array,
      default: ()=>[],
    },
  },
  methods:{
    currentPage(menu){
      return this.currentRouteName==menu.name;
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  }
}
</script>

<style media="screen">
  #logo {
    height: 3.5rem !important;
  }
  .menu{
    height: 100% !important;
    background: #fff !important;
  }
  .menu-list li svg{
    font-size: 14px !important;
  }
</style>
