<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">

        <!-- <router-link to="/member" class="navbar-item is-hidden-desktop">
          <img class="ml-2" src="/tnj-logo.png" width="55">
        </router-link> -->
        <div class="navbar-item is-hidden-desktop">
          <img class="ml-2" src="/tnj-logo.png" width="55">
        </div>

        <span class="navbar-burger" @click="$emit('openDropdown')">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>

      <div class="navbar-menu"></div>

      <div class="navbar-item has-dropdown is-hoverable is-hidden-touch">
        <a class="navbar-link">
          <b class="ml-1">{{userData.name+" "+userData.lname}}</b>
        </a>

        <div class="navbar-dropdown">
          <!-- <a class="navbar-item">
            <i class="fas fa-unlock"></i>
            <span class="ml-1">change password</span>
          </a>
          <hr class="navbar-divider"> -->
          <a class="navbar-item has-text-danger" @click="$emit('logout')">
            <i class="fas fa-sign-out-alt"></i>
            <span class="ml-1">logout</span>
          </a>
        </div>
      </div>

    </div>
  </nav>
</template>


<script>
export default {
  name: 'NavBar',
  props: {
    userData: Object
  },
}
</script>

<style media="screen">
.navbar-menu{
  height: 3.8rem !important;
}
</style>
