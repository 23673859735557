<template>
  <div class="modal" :class="{'is-active':show}">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head py-0">
        <p class="modal-card-title">
          <img class="mr-md-auto" src="/tnj-logo.png" width="150">
        </p>
      </header>
      <section class="modal-card-body">
        <ul class="menu-list">
          <li v-for="(menu,key) in menus" :key="key">
            <a :class="{'is-active':currentPage(menu)}" @click="redirect(menu.href);$emit('close');" v-if="menu.href">
              <i :class="menu.icon"></i>
              <span class="pl-2">{{menu.title}}</span>
            </a>
            <a @click="$emit(menu.emit)" v-else>
              <i :class="menu.icon"></i>
              <span class="pl-2">{{menu.title}}</span>
            </a>
          </li>
        </ul>
        <div class="px-3">
          <hr class="my-3">
          <b class="mx-auto is-size-6">{{userData.name+" "+userData.lname}}</b>
          <!-- <hr class="my-3">
          <a @click="$emit('open_change_password');$emit('close')">
            <i class="fas fa-unlock"></i>
            <span class="ml-1">change password</span>
          </a> -->
          <hr class="my-3">
          <a class="has-text-danger" @click="$emit('logout')">
            <i class="fas fa-sign-out-alt"></i>
            <span class="ml-1">logout</span>
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalMenu',
  props: {
    show: Boolean,
    userData: Object,
    menus: Array,
  },
  methods:{
    currentPage(menu){
      return this.currentRouteName==menu.name;
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  }
}
</script>

<style scoped="">
  .modal .modal-card{
    max-width: 275px !important;
  }
</style>
